import { Box, Button, Grid, Typography } from "@mui/material";
import "./App.css";
import logo from "./logo.png";
import { makeStyles } from "@mui/styles";
import { useTheme } from "@mui/material/styles";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import ContactForm from "./contactForm";

const useStyles = makeStyles(() => {
  const theme = useTheme();
  return {
    root: {
      padding: theme.spacing(2),
      backgroundColor: "#f8f8f8",
      [theme.breakpoints.up("md")]: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      },
    },
    logoContainer: {
      textAlign: "center",
      // height: "100%",
    },
    logo: {
      maxWidth: "30%",
      height: "auto",
    },
    text: {
      color: "rgba(0, 0, 0, 0.87)",
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
      fontSize: "1.2rem",
      margin: theme.spacing(1),
    },
    twitterLink: {
      display: "inline-flex", // Use inline-flex for aligning icon with text
      alignItems: "center",
      color: "#181818", // Twitter color
      textDecoration: "none",
    },
    twitterIcon: {
      marginRight: theme.spacing(2),
    },
  };
});

function App() {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} className={classes.logoContainer}>
          <div>
            <img src={logo} className={classes.logo} alt="logo" />
          </div>
        </Grid>
        <Grid item xs={12} md={6} sx={{ padding: "10px" }}>
          <Typography className={classes.text} variant="p">
            In an era where technology is rapidly evolving, CorticalFactory
            emerges as a beacon of innovation, determined to disrupt traditional
            industries with groundbreaking AI pipelines. Positioned at the
            cutting edge of the technological revolution, CorticalFactory is not
            just a company; it's a catalyst for redefining what is possible in
            the business world through the power of artificial intelligence
            (AI).
            <br />
            <br />
          </Typography>
          <Typography className={classes.text} variant="p">
            AI has transitioned from a novel concept to a core component of
            modern business strategies. By automating complex processes,
            analyzing vast data sets, and enhancing decision-making, AI
            technologies offer a multitude of benefits. They streamline
            operations, enable precise data analysis, enrich customer
            experiences, and foster innovative solutions. In this digital age,
            businesses that leverage AI are often those that stay ahead. While
            embracing the transformative power of AI, CorticalFactory remains
            committed to addressing ethical concerns. The company ensures its AI
            solutions are developed with considerations for data privacy,
            algorithmic bias, and potential job displacement. Looking ahead,
            CorticalFactory is not just keeping pace with AI trends but is
            actively shaping the future of AI in business, focusing on
            sustainable and ethical AI development.
            <br />
            <br />
          </Typography>
          <Typography className={classes.text} variant="p">
            CorticalFactory is more than a company; it's a visionary force in
            the AI landscape, seamlessly integrating AI solutions into various
            industries. By continuously innovating and responsibly deploying AI
            technologies, CorticalFactory is not just participating in the
            business world's digital transformation; it's leading it. The future
            of business, powered by AI, is not just a dream but a reality being
            crafted by CorticalFactory.
            <br />
            <br />
          </Typography>
          <hr />
          <div style={{ marginTop: 20 }}>
            <a
              href="https://twitter.com/corticalfactory"
              className={classes.twitterLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <TwitterIcon className={classes.twitterIcon} />
            </a>
            <a
              href="https://github.com/corticalfactory"
              className={classes.twitterLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <GitHubIcon className={classes.twitterIcon} />
            </a>
          </div>
          {/* <ContactForm /> */}
        </Grid>
        <Grid item xs={12} md={2}></Grid>
      </Grid>
    </Box>
  );
}

export default App;
